import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import "../styles/Experience.css";

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#502f64">
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2016 - 2018"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Xi'an Jiaotong-Liverpool University
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Bachelor's Degree
          </h4>
          <h5> Electrical and Electronic Engineering </h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2018 - 2020"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            University of Liverpool
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Bachelor's Degree
          </h4>
          <h5> Electrical and Electronic Engineering </h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2019.10 - 2020.04"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Alder Hey Hospital / University of Liverpool
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Liverpool, UK</h4>
          <h5> Student Researcher </h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2021 - 2023"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            University of Washington
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Master's Degree
          </h4>
          <h5>
            {" "}
            Electrical and Computer Engineering - Computer Enginneering / Data
            Science
          </h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2022.01 - 2022.06"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Zero Avia / University of Washington
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Seattle, WA, USA
          </h4>
          <h5> Software Engineer (Capstone Team Member, Part-Time) </h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2022.07 - 2022.10"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Sensors, Energy and Automation Labroratory (SEAL)
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Seattle, WA, USA
          </h4>
          <h5> Software Developer </h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2023.04 - 2023.06"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">humanID</h3>
          <h4 className="vertical-timeline-element-subtitle">Remote, USA</h4>
          <h5> Software Developer Intern </h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2023.06 - 2023.09"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Arista Networks</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Santa Clara, CA, USA
          </h4>
          <h5> Software Engineering Intern </h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2024.02"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            UW Information School - Volitional AI Lab
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Seattle, WA, USA
          </h4>
          <h5> Research Assistant </h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2024.11"
          contentStyle={{ background: "#edd7e5" }}
          iconStyle={{ background: "#502f64", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Fenz.ai</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Palo Alto, CA, USA
          </h4>
          <h5> Research Assistant </h5>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
